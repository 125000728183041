<template>
    <div class="rv-view-auth__login mt-9">
        <v-form :disabled="loading" ref="form" @submit.prevent>
            <v-text-field label="Email" flat outlined :rules="emailR" v-model="email" type="email" />
            <v-text-field label="Contraseña" flat autocomplete outlined v-model="password" :rules="required"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'" />

            <v-checkbox v-model="checkbox" class="mt-0" label="Recordarme" />

            <v-btn block large :disabled="loading" :loading="loading" depressed color="primary" @click="onSubmit">
                Ingresar
            </v-btn>

        </v-form>
        <v-btn color="grey" outlined block large depressed class="mt-4" :to="{ name: 'Recovery' }">
            <span class="primary--text">Recuperar mi contraseña</span>
        </v-btn>
    </div>
</template>

<script>
import { auth } from "@/firebase";
import {
    signInWithEmailAndPassword,
    setPersistence,
    browserSessionPersistence,
    browserLocalPersistence,
} from "firebase/auth";
import Rules from "@/mixins/rules";

export default {
    data: () => ({
        email: "",
        password: "",
        checkbox: false,
        loading: false,
        showPassword: false,
    }),

    mixins: [Rules],

    methods: {
        async onSubmit() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                const persistence = this.checkbox
                    ? browserLocalPersistence
                    : browserSessionPersistence;
                await setPersistence(auth, persistence)
                    .then(() => {
                        return signInWithEmailAndPassword(auth, this.email, this.password);
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        console.debug(errorMessage, errorCode);
                    });
                this.loading = false;
            }
        },
    },
};
</script>